












































































/// <reference path='../interfaces/IAvailableActions.d.ts'/>
import Vue from 'vue';
import { BTooltip } from 'bootstrap-vue';

declare interface IGuideMeHeaderProps {
	availableActions: IAvailableActions[];
	activeProcess: IActiveProcess;
	currentProcessStep: number;
}
declare interface IGuideMeHeaderData {}
declare interface IGuideMeHeaderMethods {
	isActiveAction(stepName: string): boolean;
}
declare interface IGuideMeHeaderComputed {}

declare interface IActiveProcess {
	name: string;
	steps: Array<any>;
}

const CollapsedDots = {
	props: {
		step: { type: Number, required: true },
		total: { type: Number, required: true }
	},
	components: { BTooltip },
	render: function(ce) {
		const _this = this as any;
		const _id = `cd-${Math.floor(Math.random() * 10000)}`;
		return ce('section', { domProps: { id: _id } }, [
			ce(
				'ul',
				{
					class: ['d-flex', 'mb-0', 'p-0']
				},
				[
					ce(
						'li',
						{
							class: ['icon']
						},
						[
							ce(
								'div',
								{
									class: [
										'check-circle',
										'd-flex',
										'justify-content-center',
										'selected'
									]
								},
								[
									ce(
										'span',
										{
											class: ['step-number', 'my-auto']
										},
										_this.step
									)
								]
							)
						]
					),
					ce(
						'li',
						{
							class: ['icon']
						},
						[
							ce(
								'span',
								{ class: ['h1', 'text-light', 'step-divider'] },
								'/'
							)
						]
					),
					ce(
						'li',
						{
							class: ['icon']
						},
						[
							ce(
								'div',
								{
									class: [
										'check-circle',
										'd-flex',
										'justify-content-center'
									]
								},
								[
									ce(
										'span',
										{
											class: ['step-number', 'my-auto']
										},
										_this.total
									)
								]
							)
						]
					)
				]
			),
			ce(
				'b-tooltip',
				{
					props: {
						target: _id,
						placement: 'right',
						variant: 'light',
						'custom-class': 'font-weight-bold'
					}
				},
				_this.$t('guide_me.step_description', {
					'0': _this.step,
					'1': _this.total
				})
			)
		]);
	}
};

export default Vue.extend<
	IGuideMeHeaderData,
	IGuideMeHeaderMethods,
	IGuideMeHeaderComputed,
	IGuideMeHeaderProps
>({
	components: {
		BTooltip,
		CollapsedDots
	},
	props: {
		availableActions: {
			type: Array as () => IAvailableActions[],
			required: true
		},
		activeProcess: {
			type: Object
		},
		currentProcessStep: {
			type: Number
		}
	},
	computed: {
		activeStepIndex: function() {
			const _this = this;
			if (!_this.activeProcess || !_this.availableActions) return -1;
			const processSteps = _this.activeProcess.steps;
			let result = 0;
			if (processSteps) {
				let stepIndex = 0;
				while (
					processSteps.length > stepIndex &&
					processSteps[stepIndex] &&
					processSteps[stepIndex + 1] &&
					processSteps[stepIndex].index !== _this.currentProcessStep
				) {
					stepIndex++;
				}

				while (
					_this.availableActions.length > result &&
					_this.availableActions[result] &&
					_this.availableActions[result].stepName !==
						processSteps[stepIndex].component
				) {
					result++;
				}
			}
			return result;
		}
	},
	methods: {
		isActiveAction: function(stepName: string) {
			let _this = this;
			let processSteps = _this.activeProcess.steps;
			if (processSteps && processSteps.length > 0) {
				let matchingProcessStep = processSteps.find(
					step => step.index === _this.currentProcessStep
				);
				if (
					(matchingProcessStep &&
						matchingProcessStep.component === stepName) ||
					(stepName === 'FormsOverview' &&
						matchingProcessStep.component ===
							'SignatureFormCompletion')
				) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		}
	}
});
