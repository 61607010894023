export default class CloudDocumentInfo {
	public FirstName: string = '';
	public LastName: string = '';
	public BirthDate: string = '';
	public PatientEmail: string = '';
	public PatientCellPhone: string = '';
	public OptOutEmails: boolean = false;
	public OptOutTexts: boolean = false;

	//"FirstName": "Cindy",
	//"LastName": "Brady",
	//"BirthDate": "1992-03-06T05:00:00Z"
}
