












import Vue from 'vue';
import { facilityInfoHandler } from '@/facility-info-handler';
import { EventBus } from '@/event-bus';

export default Vue.extend({
	data: () => {
		return {
			banner: ''
		};
	},
	created() {
		facilityInfoHandler.getFacilityLogo();
		this.banner = facilityInfoHandler.Banner;
		EventBus.$on(
			'update-banner',
			(logoSrc: string) => (this.banner = logoSrc)
		);
	}
});
