import { aw_api, AxiosResponse } from '@/aw_api';
import User from './models/user';
import CloudDocument from './models/GBS.AW.SCS/cloudDocumentModel';
import CloudDocumentInfo from './models/GBS.AW.SCS/cloudDocumentInfoModel';
import IdentificationVerification from './models/GBS.AW.SCS/Items/identificationVerificationModel';
import CloudDocumentItems from './models/GBS.AW.SCS/cloudDocumentItemsModel';
import { EventBus } from '@/event-bus';
import { documentHandler } from '@/document-handler';
import { environment } from '@/environment';

class Auth {
	private _urlVault: string = '';
	private _cloudDocument: CloudDocument | null = null;
	private _urlUserHash: String = '';
	private _expirationTimer: number | null = null;

	// Property Accessors & Computations
	get isAuthenticated(): boolean {
		let authCookie = this._getAuthCookie();
		this._refreshExpirationTimer(authCookie || '');
		return authCookie ? true : false;
	}

	get isValidated(): boolean {
		const isValidated =
			this.isAuthenticated && this._getValidationCookie() ? true : false;
		if (isValidated && !this._cloudDocument) {
			this._refreshCloudDocument();
		}
		return isValidated;
	}

	get isCurrentSession(): boolean {
		let sessionState = this._isCurrentSession();

		if (sessionState !== 'false' && sessionState !== 'true') {
			EventBus.$emit('session-mismatch');
			return false;
		}
		return true;
	}

	get currentUser(): User {
		return this._getUser();
	}

	get cloudDocument(): CloudDocument {
		return this._cloudDocument || new CloudDocument();
	}

	set cloudDocument(val: CloudDocument) {
		this._cloudDocument = val;
		documentHandler.UpdateDocuments(val);
		EventBus.$emit('forms');
		EventBus.$emit('appointments');
		EventBus.$emit('insurances');
		EventBus.$emit('identificationcard');
		this._refreshExpirationTimer(this._getAuthCookie() || '');
	}

	set cloudDocument2(val: CloudDocument) {
		this._cloudDocument = val;
	}

	set urlVault(id: string) {
		this._urlVault = id;
	}

	get urlVault(): string {
		return this._urlVault;
	}

	set urlUserHash(hash: string) {
		this._urlUserHash = hash;
	}

	get vaultId(): string | null {
		return this._parseVaultFromCookie(this._getAuthCookie() || '');
	}

	get scsDocumentId(): string {
		return this._getUserDocumentId() || '';
	}

	get identityId(): string | null {
		return this._parseIdentityFromCookie(this._getAuthCookie() || '');
	}

	// Methods

	public logOut(
		shouldEmit: boolean = true,
		document: IAWSignatureDocument | null = null
	): void {
		if (document !== null) {
			documentHandler.ModifyDocument(document);
			documentHandler.SaveDocumentsToSCS(undefined, undefined, () =>
				this.doLogOut(shouldEmit)
			);
		} else {
			this.doLogOut(shouldEmit);
		}
	}

	private doLogOut(shouldEmit: boolean): void {
		this._clearAuthCookie();
		this._clearValidationCookie();

		if (shouldEmit) {
			EventBus.$emit('auth');
		}
	}

	public submitValidation(data: any, recursive: boolean = false): void {
		if (!data || data == {}) {
			this._clearValidationCookie();
			return;
		}

		if (!this._cloudDocument && !recursive) {
			this._refreshCloudDocument(() => this.submitValidation(data, true));
			return;
		}

		const cloudDocument = new CloudDocument(
			this._cloudDocument || new CloudDocument()
		);

		cloudDocument.Info =
			cloudDocument.Info ||
			this._getUserData() ||
			new CloudDocumentInfo();
		if (!cloudDocument.Info) return;

		let newValidation = new IdentificationVerification();

		const pad = (i: string | number): string => ('0' + i).slice(-2);
		const now = new Date();
		newValidation.ReceivedUTC = `${
			pad(now.getUTCMonth() + 1) // Zero-based
		}-${pad(now.getUTCDate())}-${now.getUTCFullYear()} ${pad(
			now.getUTCHours()
		)}:${pad(now.getUTCMinutes())}:${pad(now.getUTCSeconds())} Z`;

		newValidation.DataJSON = JSON.stringify(data);

		newValidation.AgreementGuid = this.identityId || '';
		cloudDocument.Items = cloudDocument.Items || new CloudDocumentItems();
		cloudDocument.Items.IdentificationVerifications =
			cloudDocument.Items.IdentificationVerifications || [];

		cloudDocument.Items.IdentificationVerifications.push(newValidation);

		if (
			!(cloudDocument.Updates.indexOf('IdentificationVerification;') >= 0)
		) {
			cloudDocument.Updates += 'IdentificationVerification;';
		}
		const _this = this;
		if (!_this._getUserDocumentId()) {
			_this.logOut();
			return;
		}
		aw_api.axios
			.post(
				`v1/SCS/IdentityVerification/${this._getUserDocumentId()}`,
				newValidation
			)
			.then((response: AxiosResponse) => {
				if (response.status === 202) {
					_this._setValidationCookie();
					_this.cloudDocument = cloudDocument;
				} else {
					_this._clearValidationCookie();
				}
			})
			.catch(() => {
				_this._clearValidationCookie();
			})
			.finally(() => EventBus.$emit('validation'));
	}

	public refreshAuthExpiration(): void {
		this._refreshExpirationTimer(this._getAuthCookie() || '');
	}

	public clearAuthCookie() {
		this._clearAuthCookie();
	}

	public clearValidationCookie() {
		this._clearValidationCookie();
	}

	private _clearAuthCookie() {
		// Get the current query string
		const queryString = window.location.search;

		const cookieValue = `my-new-cookie-value${queryString}`;

		let cookieReset = `AW-P= ;myCookie=${cookieValue}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
		if (environment.variable.COOKIE_DOMAIN)
			cookieReset += `; domain=${environment.variable.COOKIE_DOMAIN}`;
		document.cookie = cookieReset;
		this._clearExpirationTimer();
	}

	private _clearValidationCookie() {
		let cookieReset =
			'AW-V= ; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
		document.cookie = cookieReset;
	}

	private _getAuthCookie() {
		const authCookieData: string | null = atob(
			(document.cookie
				.split(';')
				.map(i => i.trim().split('='))
				.filter(i => i[0] == 'AW-P')[0] || ['', ''])[1].split('.')[1] ||
				''
		);

		if (!authCookieData) return null;
		const groupsid = this._parseVaultFromCookie(authCookieData);

		const hash = this._parseHashFromCookie(authCookieData);

		if (
			!groupsid ||
			groupsid.toLowerCase() !== this._urlVault.toLowerCase() ||
			!hash ||
			hash.toLowerCase() !== this._urlUserHash.toLowerCase()
		)
			return null;
		return authCookieData;
	}

	private _isCurrentSession() {
		const authCookieData: string | null = atob(
			(document.cookie
				.split(';')
				.map(i => i.trim().split('='))
				.filter(i => i[0] == 'AW-P')[0] || ['', ''])[1].split('.')[1] ||
				''
		);

		if (!authCookieData) return 'false';

		const hash = this._parseHashFromCookie(authCookieData);

		if (!hash || hash.toLowerCase() !== this._urlUserHash.toLowerCase())
			return hash?.toLowerCase();

		return 'true';
	}
	private _parseVaultFromCookie(authCookieData: string): string | null {
		return this._parseValueFromCookie(authCookieData, 'groupsid');
	}

	private _parseHashFromCookie(authCookieData: string): string | null {
		return this._parseValueFromCookie(
			authCookieData,
			'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/hash'
		);
	}

	private _parseIdentityFromCookie(authCookieData: string): string | null {
		return this._parseValueFromCookie(authCookieData, 'nameid');
	}

	private _parseExpirationFromCookie(authCookieData: string): string | null {
		return this._parseValueFromCookie(
			authCookieData,
			'http://schemas.microsoft.com/ws/2008/06/identity/claims/expiration'
		);
	}

	private _parseValueFromCookie(
		authCookieData: string,
		key: string
	): string | null {
		if (!authCookieData) return null;
		const splitResult = authCookieData.split(`"${key}":"`);
		if (splitResult.length < 2) {
			return null;
		}
		return splitResult[1].split('"')[0];
	}

	private _getUser(): User {
		const userInfo = this._getUserInfo();
		if (!userInfo) return new User();

		let result = new User();
		result.familyName = userInfo.LastName || '';
		result.givenName = userInfo.FirstName || '';
		result.emailAddress = userInfo.PatientEmail || '';
		result.cellPhoneNumber = userInfo.PatientCellPhone || '';
		result.optOutEmails = userInfo.OptOutEmails || false;
		result.optOutTexts = userInfo.OptOutTexts || false;

		this._refreshExpirationTimer(this._getAuthCookie() || '');
		return result;
	}

	private _getUserData(): CloudDocumentInfo | null {
		const authCookieData = this._getAuthCookie();
		if (!authCookieData) return null;

		return JSON.parse(
			JSON.parse(
				authCookieData
					.split(
						'http://schemas.microsoft.com/ws/2008/06/identity/claims/'
					)
					.join('')
			).userdata || {}
		);
	}

	private _getUserDocumentId(): string | null {
		const authCookieData = this._getAuthCookie();
		if (!authCookieData) return null;

		return (
			JSON.parse(
				authCookieData
					.split(
						'http://schemas.microsoft.com/ws/2008/06/identity/claims/'
					)
					.join('')
			).primarysid || ''
		);
	}

	private _getUserInfo(): CloudDocumentInfo | null {
		const userData = this._getUserData();
		if (!userData) return null;

		return userData || new CloudDocumentInfo();
	}

	private _getValidationCookie(): string {
		return (document.cookie
			.split(';')
			.map(i => i.trim().split('='))
			.filter(i => i[0] == 'AW-V')[0] || ['', ''])[1];
	}

	private _setValidationCookie(): void {
		document.cookie = `AW-V=true;path=/;`;
	}

	private _refreshCloudDocument(callback?: any): void {
		const _this = this;
		aw_api.axios
			.get(`v1/SCS/Document`)
			.then((response: AxiosResponse) => {
				_this._refreshExpirationTimer(_this._getAuthCookie() || '');

				if (response.status === 200) {
					_this.cloudDocument = response.data;
					documentHandler.UpdateDocuments(_this.cloudDocument);
				}
			})
			.finally(() => {
				EventBus.$emit('forms');
				EventBus.$emit('appointments');
				EventBus.$emit('insurances');
				EventBus.$emit('identificationcard');
				if (callback && typeof callback === 'function') {
					callback();
				}
			});
	}

	private _refreshExpirationTimer(authCookieData: string): void {
		if (!authCookieData) {
			this._clearExpirationTimer();
			return;
		}
		let trueExpirationString = this._parseExpirationFromCookie(
			authCookieData
		);
		if (!trueExpirationString) {
			this._clearExpirationTimer();
			return;
		}

		// Get the amount of time until expiration time and then subtract five minutes
		let trueExpiration = new Date(trueExpirationString);
		let expirationWarning =
			(trueExpiration as any) - (new Date() as any) - 5 * 60 * 1000;

		// Set a timer for that period and trigger an expiration imminent event
		this._clearExpirationTimer();
		this._expirationTimer = setTimeout(
			() => EventBus.$emit('expiration-imminent'),
			expirationWarning
		);
	}
	private _clearExpirationTimer(): void {
		clearTimeout(this._expirationTimer || undefined);
		this._expirationTimer = null;
	}
}

export const auth = new Auth();
