var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"header d-none",attrs:{"toggleable":"sm","type":"dark","variant":_vm.variant}},[_c('a',{staticClass:"skipmain btn btn-light",attrs:{"href":"#main","id":"skipMain"}},[_vm._v(_vm._s(_vm.$t('header.skip_to_main_content')))]),_c('span',{staticClass:"header-content text-truncate align-items-center",class:{ 'd-none d-md-flex': _vm.showGuideMe }},[(_vm.hasSlotData)?_c('b-navbar-brand',{staticClass:"d-none d-md-inline mr-3 text-truncate p-0"},[_c('div',{staticClass:"mb-0 inherit-display"},[_vm._t("default")],2)]):(
				_vm.user.hasGreetingData &&
					_vm.$route.name === 'home' &&
					(!_vm.showHome || _vm.currentProcess.name === 'menu-direct')
			)?_c('b-navbar-brand',{staticClass:"mr-5",class:_vm.hasSlotData ? 'd-none d-md-inline p-0' : ''},[(_vm.user.photo !== '')?_c('div',{staticClass:"img-thumbnail photo mb-0 h3",style:({ backgroundImage: 'url(' + _vm.user.photo + ')' })}):_vm._e(),(_vm.user.givenName !== '')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('header.greeting', { name: _vm.user.givenName }))+" ")]):_vm._e()]):_vm._e()],1),(
			_vm.showHome &&
				_vm.currentProcess.name !== 'menu-direct' &&
				_vm.currentProcess.name !== 'functional' &&
				_vm.currentProcess !== 'direct:FormsOverview' &&
				_vm.$route.name === 'home'
		)?_c('b-navbar-nav',{class:{ 'mr-auto': !_vm.showGuideMe }},[_c('b-nav-item',{staticClass:"icon",attrs:{"link-classes":"p-0","title":_vm.$t('common.home'),"link-attrs":{
				'aria-label': _vm.$t('common.home'),
				role: 'button'
			}},on:{"click":_vm.changeProcessStep}},[_c('font-awesome-icon',{attrs:{"icon":"home","aria-hidden":"true"}})],1)],1):_vm._e(),(_vm.showGuideMe)?_c('guide-me',{attrs:{"availableActions":_vm.availableActions,"activeProcess":_vm.currentProcess,"currentProcessStep":_vm.currentProcessStep,"showHome":_vm.showHome}}):_vm._e(),_c('b-navbar-toggle',{ref:"toggler",attrs:{"target":"nav-collapse","data-expand-label":_vm.$t('header.expand_menu'),"data-collapse-label":_vm.$t('header.collapse_menu')}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto bg-primary align-items-center"},[_c('b-nav-text',{staticClass:"ml-sm-2 py-0",attrs:{"aria-hidden":"false","title":_vm.$t('language_selector.language_selector'),"link-attrs":{
					'aria-label': _vm.$t('language_selector.language_selector')
				}}},[_c('LanguageSelector',{attrs:{"toRight":""}}),_c('hr',{staticClass:"d-sm-none w-100 border-top"})],1),_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon ml-sm-2",attrs:{"link-classes":"d-flex d-small-block py-0 align-items-center menu-item","aria-hidden":"false","title":_vm.$t('footer.accessibility_options'),"link-attrs":{
					'aria-label': _vm.$t('footer.accessibility_options'),
					role: 'button'
				}},on:{"click":this.accessibilityClicked}},[_c('font-awesome-icon',{attrs:{"icon":"universal-access","aria-label":_vm.$t('footer.accessibility_options'),"aria-hidden":"false"}}),_c('span',{staticClass:"d-sm-none ml-3 h6 my-0 menu-item-label"},[_vm._v(_vm._s(_vm.$t('footer.accessibility_options')))])],1),_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon ml-sm-2 my-3 my-sm-0",attrs:{"link-classes":"d-flex d-small-block py-0 align-items-center menu-item","aria-hidden":"false","title":_vm.$t('common.help'),"link-attrs":{
					'aria-label': _vm.$t('common.help'),
					role: 'button',
					id: 'header-help'
				}},on:{"click":function($event){return _vm.$bvModal.show('modal-help')}}},[_c('font-awesome-icon',{attrs:{"icon":"question-circle","aria-label":_vm.$t('common.help'),"aria-hidden":"false"}}),_c('span',{staticClass:"d-sm-none ml-3 h6 my-0 menu-item-label"},[_vm._v(_vm._s(_vm.$t('common.help')))])],1),(_vm.user.hasGreetingData && _vm.$route.name !== 'landing')?_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon ml-sm-2",attrs:{"link-classes":"d-flex d-small-block py-0 align-items-center menu-item","aria-hidden":"false","title":_vm.$t('common.logout'),"link-attrs":{
					'aria-label': _vm.$t('common.logout'),
					role: 'button'
				}},on:{"click":function($event){return _vm.logOut()}}},[_c('font-awesome-icon',{attrs:{"icon":"sign-out-alt","aria-hidden":"false","aria-label":_vm.$t('common.logout')}}),_c('span',{staticClass:"d-sm-none ml-3 h6 my-0 menu-item-label"},[_vm._v(_vm._s(_vm.$t('common.logout')))])],1):_vm._e()],1)],1),_c('b-modal',{attrs:{"centered":"","id":"modal-help","title":_vm.$t('common.help')},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
				var ok = ref.ok;
return [_c('b-button',{staticClass:"standard-btn",on:{"click":function($event){return ok()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.ok')))])])]}}])},[_c('help',{attrs:{"content":_vm.helpContent}})],1),_c('b-modal',{attrs:{"centered":"","id":"modal-logout","title":_vm.$t('common.logout')}},[_c('template',{slot:"default"},[_c('label',[_vm._v(_vm._s(_vm.$t('closing.save_documents_logout')))])]),_c('template',{slot:"modal-footer"},[_c('b-button',{staticClass:"standard-btn",attrs:{"variant":"secondary"},on:{"click":function () {
						_vm.$emit('do-log-out');
						this$1.$bvModal.hide('modal-logout');
					}}},[_vm._v(" "+_vm._s(_vm.$t('closing.dont_save'))+" ")]),_c('b-button',{staticClass:"standard-btn",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveOnLogOut()}}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }