




























































































///<reference path='../interfaces/IAWSignatureDocument.d.ts'/>
import { BDropdown, BFormCheckbox, BCollapse, VBToggle } from 'bootstrap-vue';
import Vue from 'vue';
import { aw_api, AxiosResponse } from '@/aw_api';
import { documentHandler } from '@/document-handler';
import { auth } from '@/auth';
import { EventBus } from '@/event-bus';

declare interface ILanguage {
	name: string;
	nativeName: string;
	code: string;
	direction: string;
	flag: string;
}
declare interface ILanguageSelectorState {
	languages: ILanguage[];
	formLangChange: Boolean;
	continueBnt: Boolean;
	previousLang: string;
	newLanguage: string;
	forms: Document[];
	avaDocList: IDocumentMetaData[];
	avaNewLangDocCount: number;
	avaDocCount: number;
	disclaimerOpen: Boolean;
	disclaimerContent: any | any[];
}
declare interface IWebLanguage {
	NativeName: string;
	Direction: string;
	LocaleCode: string;
	Name: string;
}

export default Vue.extend({
	props: {
		toRight: {
			type: Boolean,
			default: false
		}
		// user: {
		// 	type: User,
		// 	required: true
		// }
	},
	components: {
		BDropdown,
		BFormCheckbox,
		// eslint-disable-next-line vue/no-unused-components
		BCollapse
	},
	directives: {
		'b-toggle': VBToggle
	},
	data(): ILanguageSelectorState {
		return {
			languages: [],
			formLangChange: false,
			continueBnt: false,
			previousLang: 'English',
			newLanguage: 'English',
			forms: [],
			avaDocList: [],
			avaNewLangDocCount: 0,
			avaDocCount: 0,
			disclaimerOpen: false,
			disclaimerContent: ''
		};
	},
	created() {
		this.setDisclaimer();
		this.getSupportedLanguages();
		EventBus.$on('langLoad', () => this.onload());
	},
	computed: {
		selectedLanguage(): ILanguage {
			return this.languages.find(
				lang => lang.code === this.$i18n.locale
			) as ILanguage;
		}
	},
	methods: {
		convertToLocalLanguage(webLang: IWebLanguage): ILanguage {
			return {
				name: webLang.Name,
				nativeName: webLang.NativeName,
				code: webLang.LocaleCode,
				direction: webLang.Direction,
				flag: webLang.LocaleCode.substr(3)
			};
		},
		async setDisclaimer() {
			this.disclaimerContent = [];
			const ce = this.$createElement;
			this.disclaimerContent.push(
				ce('div', {}, [
					ce(
						'p',
						{},
						this.$t(
							'language_selector.disclaimer_section_one'
						).toString()
					),
					ce(
						'b-button',
						{
							style: {
								'text-decoration': 'underline',
								border: 'none'
							},
							class: {
								'bg-light': true,
								'text-dark': true
							},
							directives: [
								{
									name: 'b-toggle',
									value: 'collapse-disclaimer'
								}
							]
						},
						[
							ce(
								'strong',
								{},
								this.$t(
									'language_selector.disclaimer_section_two'
								)
									.toString()
									.toUpperCase()
							),
							ce('font-awesome-icon', {
								style: {
									'margin-left': '2px'
								},
								class: {
									'fa-fw': true,
									'when-closed': true
								},
								props: {
									icon: 'plus'
								}
							}),
							ce('font-awesome-icon', {
								style: {
									'margin-left': '2px'
								},
								class: {
									'fa-fw': true,
									'when-opened': true
								},
								props: {
									icon: 'minus',
									role: 'button'
								}
							})
						]
					),
					ce(
						'b-collapse',
						{
							props: {
								id: 'collapse-disclaimer'
							}
						},
						[
							ce('p', {}, [
								this.$t(
									'language_selector.disclaimer_section_three'
								).toString(),
								ce(
									'strong',
									{},
									' ' +
										this.$t(
											'language_selector.disclaimer_section_not'
										)
											.toString()
											.toUpperCase() +
										' '
								),
								this.$t(
									'language_selector.disclaimer_section_four'
								).toString()
							]),
							ce(
								'p',
								{},
								this.$t(
									'language_selector.disclaimer_section_five'
								).toString()
							)
						]
					)
				])
			);
		},
		getSupportedLanguages() {
			const _this = this;
			// Go and get a list of supported languages
			aw_api.axios
				.get(`v1/LangSupport`)
				.then((response: AxiosResponse<IWebLanguage[]>) => {
					if (response && response.status === 200 && response.data) {
						if (response.data != null) {
							_this.languages = response.data.map(
								_this.convertToLocalLanguage
							);
						} else {
							_this.reset();
						}
					} else {
						_this.reset();
					}
				})
				.catch((error: Error) => {
					//eslint-disable-next-line
					console.error(error);
					this.$gtag.exception({
						description: error.message,
						fatal: false
					});
					_this.reset();
				});
		},
		async onLanguageSelected(e) {
			const _this = this;
			const changeLanguage = async (
				selectedLanguage: string,
				shouldCheckForm: boolean
			) => {
				_this.$i18n.locale = selectedLanguage;
				(_this.$refs['langdropdown'] as any).hide();
				if (shouldCheckForm) {
					_this.previousLang = _this.newLanguage;
					localStorage.setItem('SelectedLanguage', selectedLanguage);
					_this.newLanguage = _this.selectedLanguage.nativeName;
				}
				_this.disclaimerOpen = false;
				EventBus.$emit(
					'language-change',
					_this.selectedLanguage.name.toString()
				);
				//see if there is a user and if that user has forms+changable forms
				if (
					_this.previousLang != _this.newLanguage &&
					auth.isAuthenticated &&
					shouldCheckForm
				) {
					_this.checkForms();
				}
				if (
					_this.$i18n.availableLocales.indexOf(selectedLanguage) < 0
				) {
					const vaultID =
						this.$route.params.vaultId ||
						'00000000-0000-0000-0000-000000000000';
					await aw_api.axios
						.get(`v1/LangSupport/${vaultID}/${selectedLanguage}`)
						.then(function(response: AxiosResponse) {
							if (
								response &&
								response.status === 200 &&
								response.data &&
								response.data !== {}
							) {
								Object.keys(response.data).forEach(lang =>
									_this.$i18n.mergeLocaleMessage(
										lang,
										response.data[lang]
									)
								);
							}
						});
				}
			};

			let selectedLanguage: string = e.currentTarget.dataset.value;

			if (_this.previousLang !== selectedLanguage) {
				if (selectedLanguage === 'en-us') {
					await changeLanguage('en-us', true);
				} else if (!_this.disclaimerOpen) {
					await changeLanguage(selectedLanguage, false);
					_this.disclaimerOpen = true;
					await _this.setDisclaimer();
					_this.$bvModal
						.msgBoxConfirm(this.disclaimerContent, {
							centered: true
						})
						.then(accepted => {
							if (!accepted) {
								selectedLanguage = 'en-us';
							}
							changeLanguage(selectedLanguage, true);
						});
				}
			}
		},
		checkForms() {
			const _this = this;
			var allDocumentsInCurrentLanguage = true;
			const signatureDocs = documentHandler.SignatureDocuments;
			if (signatureDocs != null) {
				_this.avaDocCount = signatureDocs.length;
				// Check to see if all documents are in the same language
				allDocumentsInCurrentLanguage =
					signatureDocs.filter(i =>
						[
							_this.selectedLanguage?.name?.toLowerCase() || ''
						].includes(i.Language?.toLowerCase())
					).length == _this.avaDocCount;
				// If all documents are not in the current selected language, we get our counts.
				if (!allDocumentsInCurrentLanguage) {
					aw_api.axios
						.post(
							`v1/SCS/LanguageDocChange/${
								_this.selectedLanguage.name
							}/?documentIds=${signatureDocs
								.map(i => i.DocumentId)
								.join('&documentIds=')}`
						)
						.then(function(response: AxiosResponse) {
							if (response.data > 0) {
								_this.avaNewLangDocCount = response.data;
								(_this.$refs['langChangeNotice'] as any).show();
							}
						});
				}
			}
		},
		changeLangForms() {
			//If the user wants to change all the changable documents
			if (this.formLangChange) {
				aw_api.axios
					.get(
						`v1/SCS/LanguageDocChange/ChangeDocuments/${this.selectedLanguage.name}/${auth.scsDocumentId}`
					)
					.then(function(response: AxiosResponse) {
						if (response.status === 200) {
							auth.cloudDocument = response.data;
							EventBus.$emit('step-reset');
						}
					});
				this.formLangChange = false;
			}
			this.continueBnt = false;
			//close modal regardless of the answer
			(this.$refs['langChangeNotice'] as any).hide();
		},
		onload() {
			var lang = 'en-us';
			var localStorageLang = localStorage.getItem('SelectedLanguage');
			if (localStorageLang != null && lang !== localStorageLang) {
				if (!this.disclaimerOpen) {
					this.loadLanguages(localStorageLang);
					this.disclaimerOpen = true;
					setTimeout(() => {
						this.setDisclaimer();
						this.$bvModal
							.msgBoxConfirm(this.disclaimerContent, {
								centered: true
							})
							.then(accepted => {
								if (accepted) {
									lang = localStorageLang || 'en-us';
								} else {
									localStorage.setItem(
										'SelectedLanguage',
										lang
									);
								}
								this.loadLanguages(lang);
								this.disclaimerOpen = false;
							});
					}, 500);
				}
			} else {
				lang = localStorageLang || lang;

				this.loadLanguages(lang);
			}
		},
		loadLanguages(lang: string, attempt: number = 1) {
			const selectedLanguage: string = lang;
			const _this = this;

			try {
				_this.$i18n.locale = selectedLanguage;
			} catch (e) {
				const maxLoadLimit: number = 5;
				if (attempt++ <= maxLoadLimit) {
					setTimeout(() => _this.loadLanguages(lang, attempt), 200);
				} else {
					throw e;
				}
			}
			const vaultID =
				this.$route.params.vaultId ||
				'00000000-0000-0000-0000-000000000000';

			aw_api.axios
				.get(`v1/LangSupport/${vaultID}/${selectedLanguage}`)
				.then(function(response: AxiosResponse) {
					if (
						response &&
						response.status === 200 &&
						response.data &&
						response.data !== {}
					) {
						Object.keys(response.data).forEach(lang =>
							_this.$i18n.mergeLocaleMessage(
								lang,
								response.data[lang]
							)
						);
						EventBus.$emit('lang-set');
					}
				})
				.catch(_ => {
					this.$gtag.exception({
						description: 'An error occurred retrieving verbiage',
						fatal: false
					});
					// eslint-disable-next-line no-console
					console.error('An error occurred retrieving verbiage');
				});
		},
		reset() {
			this.languages = [
				{
					name: 'English',
					code: 'en-us',
					direction: 'ltr',
					flag: 'us',
					nativeName: 'English'
				},
				{
					name: 'Spanish',
					code: 'es-es',
					direction: 'ltr',
					flag: 'es',
					nativeName: 'Español'
				},
				{
					name: 'German',
					code: 'de-de',
					direction: 'ltr',
					flag: 'de',
					nativeName: 'Deutsche'
				},
				{
					name: 'French',
					code: 'fr-fr',
					direction: 'ltr',
					flag: 'fr',
					nativeName: 'Français'
				},
				{
					name: 'Italian',
					code: 'it-it',
					direction: 'ltr',
					flag: 'it',
					nativeName: 'Italiano'
				}
			];
		}
	}
});
