import SignatureDocument from './Items/signatureDocumentModel';
import IdentificationVerification from './Items/identificationVerificationModel';
import EmailPreference from './Items/emailPreferenceModel';
import MedicalAppointment from './Items/medicalAppointmentModel';
import AppointmentResponse from './Items/appointmentResponseModel';
import PaymentPortal from './Items/paymentPortalModel';
import MedicalInsurance from './Items/medicalInsuranceModel';
import IdentificationCard from './Items/identificationCardModel';

export default class CloudDocumentItems {
	public SignatureDocuments: SignatureDocument[] = [];
	public IdentificationVerifications: IdentificationVerification[] = [];
	public EmailPreferences: EmailPreference[] = [];
	public MedicalAppointments: MedicalAppointment[] = [];
	public AppointmentResponse: AppointmentResponse[] = [];
	public PaymentPortal: PaymentPortal = new PaymentPortal();
	public MedicalInsurances: MedicalInsurance[] = [];
	public IdentificationCard: IdentificationCard = new IdentificationCard();

	//"SignatureDocuments": []
	//"IdentificationVerifications": []
	//"EmailPreferences": []
}
