import CloudDocumentInfo from './cloudDocumentInfoModel';
import CloudDocumentItems from './cloudDocumentItemsModel';
import cloneDeep from 'lodash-es/cloneDeep';

export default class CloudDocument {
	// This model should be a near-identical reproduction of the CloudDocument SCS class JSON serialization result.
	// It does NOT refer explicitly to a signature document, but to a user's data document.

	constructor(fromCloudDocument?: CloudDocument) {
		this.Info = new CloudDocumentInfo();
		this.Items = new CloudDocumentItems();
		this.Updates = '';
		this.LastUpdatedUTCTicks = -1;
		this.OwnerId = '';

		if (fromCloudDocument) {
			const copy: CloudDocument = cloneDeep(fromCloudDocument);
			Object.keys(this).map((k, i) => (this[k] = copy[k]));
		}
	}

	public Info: CloudDocumentInfo;
	public Items: CloudDocumentItems;
	public Updates: string;
	public LastUpdatedUTCTicks: number;
	public OwnerId: string;

	//"Info": {
	//  "FirstName": "Cindy",
	//  "LastName": "Brady",
	//  "BirthDate": "1992-03-06T05:00:00Z"
	//},
	//"Items": {
	//  "SignatureDocuments": []
	//	"EmailPreferences": []
	//},
	//"Updates": "",
	//"LastUpdatedUTCTicks": 636970868095628000,
	//"OwnerId": "028e215f-9dd5-4630-8808-2d2152e3c95e"
}
