export default class User {
	private _givenName: string;
	private _familyName: string;
	private _emailAddress: string;
	private _cellPhoneNumber: string;
	private _optOutEmails: boolean;
	private _optOutTexts: boolean;
	private _photo: string; // Base64 encoded

	constructor(
		givenName?: string,
		familyName?: string,
		emailAddress?: string,
		cellPhoneNumber?: string,
		optOutEmails?: boolean,
		optOutTexts?: boolean,
		photo?: string
	) {
		this._givenName = '';
		this._familyName = '';
		this._emailAddress = '';
		this._cellPhoneNumber = '';
		this._optOutEmails = false;
		this._optOutTexts = false;
		this._photo = '';

		this.givenName = givenName || '';
		this.familyName = familyName || '';
		this.emailAddress = emailAddress || '';
		this.cellPhoneNumber = cellPhoneNumber || '';
		this.optOutEmails = optOutEmails || false;
		this.optOutTexts = optOutTexts || false;

		this.photo = photo || '';
	}

	// Property Accessors
	get familyName(): string {
		return this._familyName;
	}
	set familyName(familyName: string) {
		this._familyName = familyName.trim();
	}

	get givenName(): string {
		return this._givenName;
	}
	set givenName(givenName: string) {
		this._givenName = givenName.trim();
	}

	get emailAddress(): string {
		return this._emailAddress;
	}
	set emailAddress(emailAddress: string) {
		this._emailAddress = emailAddress.trim();
	}

	get cellPhoneNumber(): string {
		return this._cellPhoneNumber;
	}
	set cellPhoneNumber(cellPhoneNumber: string) {
		this._cellPhoneNumber = cellPhoneNumber.trim();
	}

	get optOutEmails(): boolean {
		return this._optOutEmails;
	}
	set optOutEmails(optOutEmails: boolean) {
		this._optOutEmails = optOutEmails;
	}

	get optOutTexts(): boolean {
		return this._optOutTexts;
	}
	set optOutTexts(optOutTexts: boolean) {
		this._optOutTexts = optOutTexts;
	}

	get photo(): string {
		return this._photo;
	}
	set photo(photo: string) {
		this._photo = photo.trim();
	}

	// Calculated
	get hasGreetingData(): boolean {
		return (
			(this._givenName || '').length > 0 || (this._photo || '').length > 0
		);
	}
}
