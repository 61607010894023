import { aw_api, AxiosResponse } from '@/aw_api';
import { auth } from '@/auth';
import { EventBus } from '@/event-bus';

class FacilityInfoHandler {
	private _lastInfoRequest?: number = undefined;
	private _info?: IVaultFacilityInfo = undefined;
	private _lastLogoRequest?: number = undefined;
	private _logo?: string = undefined;
	private _banner?: string = undefined;

	private _refreshIntervalMs: number = 600000; // 10 minutes, in milliseconds

	private async UpdateInfo() {
		if (!auth || !auth.urlVault) {
			this._info = undefined;
			this._lastInfoRequest = undefined;
			return;
		}

		const response: AxiosResponse = await aw_api.axios.get(
			`v1/vaultData/${auth.urlVault}/VaultFacilityInformation`
		);

		if (
			!response ||
			response.status !== 200 ||
			!response.data ||
			!response.data['English']
		) {
			this._info = undefined;
			this._lastInfoRequest = undefined;
			return;
		}
		this._info = {
			ContactFacilityName:
				response.data['English']['ContactFacilityName'] != null &&
				response.data['English']['ContactFacilityName'] != ''
					? response.data['English']['ContactFacilityName']
					: '',
			PhoneNumber:
				response.data['English']['PhoneNumber'] != null &&
				response.data['English']['PhoneNumber'] != ''
					? response.data['English']['PhoneNumber']
					: '',
			Address:
				response.data['English']['Address'] != null &&
				response.data['English']['Address'] !=
					"Please provide facility's address"
					? response.data['English']['Address']
					: '',
			AdditionalInformation:
				response.data['English']['AdditionalInformation'] != null &&
				response.data['English']['AdditionalInformation'] !=
					'Please provide any additional information about facilities address'
					? response.data['English']['AdditionalInformation']
					: ''
		};
		this._lastInfoRequest = Date.now();
	}

	private async UpdateLogo() {
		if (!auth || !auth.urlVault) {
			this._logo = undefined;
			this._banner = undefined;
			this._lastLogoRequest = undefined;
			return;
		}

		const response: AxiosResponse = await aw_api.axios.get(
			`v1/vaultData/${auth.urlVault}/VaultImages`
		);

		if (!response || response.status !== 200 || !response.data) {
			this._logo = '/img/stock/default-logo.png';
			this._banner = '/img/stock/default-banner.png';
			this._lastLogoRequest = undefined;
			return;
		}

		if (response.data['Logo']) {
			this._logo = response.data['Logo'];
			this._lastLogoRequest = Date.now();
			EventBus.$emit('update-logo', this._logo);
		}
		if (response.data['Banner']) {
			this._banner = response.data['Banner'];
			this._lastLogoRequest = Date.now();
			EventBus.$emit('update-banner', this._banner);
		}
	}

	get Info(): IVaultFacilityInfo {
		if (
			this._info === undefined ||
			this._banner == undefined ||
			(this._lastInfoRequest !== undefined &&
				Date.now() - this._refreshIntervalMs < this._lastInfoRequest)
		)
			(async () => await this.UpdateInfo())();
		return (
			this._info || {
				ContactFacilityName: '',
				PhoneNumber: '',
				Address: '',
				AdditionalInformation: ''
			}
		);
	}

	get Logo(): string {
		return this._logo || '';
	}
	get Banner(): string {
		return this._banner || '';
	}

	public getFacilityLogo() {
		if (
			this._logo === undefined ||
			this._banner == undefined ||
			(this._lastLogoRequest !== undefined &&
				Date.now() - this._refreshIntervalMs < this._lastLogoRequest)
		)
			(async () => await this.UpdateLogo())();
	}
}

export const facilityInfoHandler = new FacilityInfoHandler();
