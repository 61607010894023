



































































































































































































/// <reference path='../interfaces/IAvailableActions.d.ts'/>

import {
	BCollapse,
	BNavbar,
	BNavItem,
	BNavText,
	BNavbarNav,
	BNavbarBrand,
	BNavbarToggle
} from 'bootstrap-vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import Help from '@/components/Help.vue';
import { EventBus } from '@/event-bus';
import User from '@/models/user';
import Vue from 'vue';
import GuideMe from '@/components/GuideMeHeader.vue';

declare interface IHeaderData {
	availableActions: IAvailableActions[];
	currentProcess: IWorkFlowProcess | undefined;
	currentProcessStep: number;
	hasSlotData: boolean;
	helpContent: Function | undefined;
	validated: boolean;
	showHome: boolean;
}
declare interface IHeaderComputed {
	showGuideMe: boolean;
}
declare interface IHeaderMethods {
	accessibilityClicked(): void;
	updateHasSlotData(): void;
	logOut(): void;
	saveOnLogOut(): void;
	changeProcessStep(): void;
}
declare interface IHeaderProps {
	user: User;
	variant: String;
}

export default Vue.extend<
	IHeaderData,
	IHeaderMethods,
	IHeaderComputed,
	IHeaderProps
>({
	props: {
		user: {
			type: User,
			required: true
		},
		variant: {
			type: String,
			default: 'primary'
		}
	},
	components: {
		BCollapse,
		BNavbar,
		BNavbarBrand,
		BNavbarNav,
		BNavbarToggle,
		BNavItem,
		BNavText,
		LanguageSelector,
		GuideMe,
		Help
	},
	data: () => {
		return {
			availableActions: [],
			currentProcess: undefined,
			currentProcessStep: 0,
			hasSlotData: false,
			helpContent: undefined,
			validated: false,
			showHome: false
		};
	},
	computed: {
		showGuideMe: function() {
			// Boolean to determine when the guide me header should be displayed
			if (
				this.currentProcess?.name === 'guide-direct' &&
				this.currentProcessStep < this.currentProcess.steps.length &&
				this.currentProcessStep != 5 &&
				this.validated &&
				this.$route.name === 'home'
			) {
				return true;
			} else {
				return false;
			}
		}
	},
	methods: {
		changeProcessStep: function() {
			EventBus.$emit('go-home');
		},
		updateHasSlotData: function() {
			const defaultSlot = this.$slots['default'];
			this.hasSlotData =
				!defaultSlot ||
				defaultSlot.length !== 1 ||
				!defaultSlot[0] ||
				!defaultSlot[0].data ||
				!defaultSlot[0].data.domProps ||
				!defaultSlot[0].data.domProps.innerHTML ||
				defaultSlot[0].data.domProps.innerHTML !== '&nbsp;';
		},
		accessibilityClicked: function() {
			EventBus.$emit('toggle-show-accessibility');
		},
		logOut: function() {
			if (
				(this.currentProcess &&
					this.currentProcessStep === 2 &&
					this.currentProcess.name === 'functional') ||
				(this.currentProcess &&
					this.currentProcess.name === 'direct:FormsOverview' &&
					this.currentProcessStep ===
						this.currentProcess.steps.length - 1)
			) {
				this.$bvModal.show('modal-logout');
			} else {
				// console.log('emitting do-log-out');
				this.$emit('do-log-out', false);
			}
		},
		saveOnLogOut: function() {
			// console.log('save on logout button');
			EventBus.$emit('save-logout');
			this.$bvModal.hide('modal-logout');
		}
	},
	created() {
		this.updateHasSlotData();
		EventBus.$on(
			'actions-changed',
			newActions => (this.availableActions = newActions)
		);
		EventBus.$on(
			'process-changed',
			newProcess => (this.currentProcess = newProcess)
		);
		EventBus.$on(
			'step-changed',
			newStep => (this.currentProcessStep = newStep)
		);
		EventBus.$on(
			'update-help',
			newContent => (this.helpContent = newContent)
		);
		EventBus.$on('showHome', (showHome: boolean) => {
			this.showHome = showHome;
		});
		EventBus.$emit('header-loaded');
		EventBus.$on('validation', () => (this.validated = true));
	},
	updated() {
		this.updateHasSlotData();
	}
});
