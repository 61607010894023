









































/// <reference path='../interfaces/IVaultFacilityInfo.d.ts'/>
import Vue from 'vue';
import { aw_api, AxiosResponse } from '@/aw_api';
import { auth } from '@/auth';
import { facilityInfoHandler } from '@/facility-info-handler';
import { environment } from '@/environment';

interface FacilityDataItem {
	key: 'Name' | 'Phone' | 'Address' | 'Additional' | 'Logo';
	value: string;
}
export default Vue.extend({
	props: {
		content: {
			type: Function,
			default: null
		}
	},
	data: () => {
		return {
			apiVersion: 'Unavailable',
			webVersion: 'Unavailable'
		};
	},
	computed: {
		facilityData: function() {
			let result: FacilityDataItem[] = [];

			if (!auth || !auth.urlVault) {
				return result;
			}

			const info = facilityInfoHandler.Info;
			const logo = facilityInfoHandler.Logo;
			if (info) {
				if (info.ContactFacilityName) {
					result.push({
						key: 'Name',
						value: info.ContactFacilityName
					});
				}
				if (info.PhoneNumber) {
					result.push({ key: 'Phone', value: info.PhoneNumber });
				}
				if (info.Address) {
					result.push({ key: 'Address', value: info.Address });
				}
			}
			if (logo) {
				result.push({ key: 'Logo', value: logo });
			}

			return result;
		}
	},
	methods: {
		updateApiVersion(): void {
			const _this = this;
			aw_api.axios.get(`info/version`).then((response: AxiosResponse) => {
				if (response.status === 200) {
					_this.apiVersion = response.data;
				}
			});
		}
	},
	created() {
		const _this = this;
		_this.webVersion = environment.variable.WEB_VERSION || 'Unavailable';
		_this.updateApiVersion();
	}
});
