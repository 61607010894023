import { EventBus } from '@/event-bus';

export default class AccessibilityPanelModel {
	private _localStorageSelectorPrefix: string = 'AW-Acc-';
	private _localStorageZoom: string =
		this._localStorageSelectorPrefix + 'Zoom';
	private _localStorageEnhanceSelection: string =
		this._localStorageSelectorPrefix + 'EnhanceSelection';
	private _localStorageDyslexic: string =
		this._localStorageSelectorPrefix + 'Dyslexic';
	private _localStorageBasicMode: string =
		this._localStorageSelectorPrefix + 'BasicMode';

	private _dyslexicFont: boolean;
	private _enhanceValue: boolean;
	private _basicMode: boolean;
	private _zoomLevel: string;

	constructor() {
		// Should we be checking for a cookie or something here?
		this._dyslexicFont =
			localStorage.getItem(this._localStorageDyslexic) == 'true' || false;
		this._enhanceValue =
			localStorage.getItem(this._localStorageEnhanceSelection) ==
				'true' || false;
		this._zoomLevel =
			localStorage.getItem(this._localStorageZoom) || '100%';
		this._basicMode =
			localStorage.getItem(this._localStorageBasicMode) == 'true' ||
			false;
		EventBus.$emit('basic-mode-set', this._basicMode);
	}

	get DyslexicFont() {
		return this._dyslexicFont;
	}
	get EnhanceValue() {
		return this._enhanceValue;
	}
	get ZoomLevel() {
		return this._zoomLevel;
	}
	get BasicMode() {
		return this._basicMode;
	}

	set DyslexicFont(val: boolean) {
		this._dyslexicFont = val;
		localStorage.setItem(this._localStorageDyslexic, val ? 'true' : '');
	}
	set EnhanceValue(val: boolean) {
		this._enhanceValue = val;
		localStorage.setItem(
			this._localStorageEnhanceSelection,
			val ? 'true' : ''
		);
	}
	set ZoomLevel(val: string) {
		this._zoomLevel = val;
		localStorage.setItem(this._localStorageZoom, val);
		window.dispatchEvent(new Event('resize'));
	}
	set BasicMode(val: boolean) {
		if (this._basicMode !== val) {
			this._basicMode = val;
			localStorage.setItem(
				this._localStorageBasicMode,
				val ? 'true' : ''
			);
			EventBus.$emit('basic-mode-set', val);
		}
	}
}
