export default class IdentificationCard {
	public CardId: string = '';
	public ReceivedUTC: string = '';
	public Issuer: string = '';
	public CardNumber: string = '';
	public CardType: string = '';
	public FrontImage: string = '';
	public BackImage: string = '';
	public ExpirationDate: string = '';
}
