









































import Vue from 'vue';

export default Vue.extend({
	props: {
		icon: { type: String, required: true },
		label: { type: String, required: true },
		action: { type: Function, required: true }
	},
	computed: {}
});
