















































































































































/// <reference path='interfaces/IAWSignatureDocument.d.ts'/>
/// <reference path='interfaces/IWorkFlowProcess.d.ts'/>
/// <reference path='interfaces/IAvailableActions.d.ts'/>

import Header from '@/components/Header.vue';

import AccessibilityPanelModel from '@/models/accessibilityPanelModel';
const AccessibilityPanel = () =>
	import(
		/* webpackChunkName: "accessibility-panel" */ '@/components/AccessibilityPanel.vue'
	);
import Vue from 'vue';
import { BProgress, BImg } from 'bootstrap-vue';
import { EventBus } from '@/event-bus';
import { auth } from '@/auth';
import User from './models/user';
import './styles/sass/global.scss';
import { aw_api, AxiosResponse } from '@/aw_api';
import { mapActions, mapGetters } from 'vuex';

import Footer2 from './components/Footer2.vue';

interface IAppData {
	user: User;
	headerContentText: string;
	headerStyling: string;
	showAccessibility: boolean;
	accessibilityPanelModel: AccessibilityPanelModel;
	document: IAWSignatureDocument | null;
	expirationCountdown: number;
	expirationTimer: number | null;
	currentProcess: IWorkFlowProcess | undefined;
	currentProcessStep: number;
	showWrongSessionModal: boolean;
	kioskMode: boolean;
	kioskBaseUrlRedirect: string;
}
interface IAppComputed {
	accessibilityClasses: string;
	appClasses: string;
	headerContent: string;
	headerClasses: string;
	showExpirationModal: boolean;
}
interface IAppMethods {
	logOut: () => void;
	reset: () => void;
	expirationTick: () => void;
	refreshExpirationTimer: () => void;
	clearExpirationTimer: () => void;
	extendExpiration: () => void;
	resetCookies: () => void;
}

export default Vue.extend<IAppData, IAppMethods, IAppComputed>({
	data: () => {
		return {
			user: new User(),
			headerContentText: '',
			headerStyling: '',
			showAccessibility: false,
			accessibilityPanelModel: new AccessibilityPanelModel(),
			document: null,
			expirationCountdown: 0,
			expirationTimer: null,
			currentProcess: undefined,
			currentProcessStep: 0,
			showWrongSessionModal: false,
			kioskMode: false,
			kioskBaseUrlRedirect: ''
		};
	},
	components: {
		AccessibilityPanel,
		Header,
		BProgress,
		Footer2
	},
	computed: {
		...mapGetters(['isKioskModeEnabled']),
		accessibilityClasses: function() {
			const classList: string[] = [];
			if (this.showAccessibility) {
				classList.push('show-accessibility');
			} else {
				classList.push('hide-accessibility');
			}
			return classList.join(' ');
		},
		appClasses: function() {
			const classList: string[] = [];

			// Accessibility
			if (this.accessibilityPanelModel.EnhanceValue) {
				this.$gtag.event('accessibility-selection', {
					event_category: 'accessibility',
					event_label: 'enhance-value'
				});
				classList.push('enhance-selection');
			}
			if (this.accessibilityPanelModel.DyslexicFont) {
				this.$gtag.event('accessibility-selection', {
					event_category: 'accessibility',
					event_label: 'dyslexic-font'
				});
				classList.push('font-dyslexic');
			}
			classList.push(
				`zoom-${this.accessibilityPanelModel.ZoomLevel.replace(
					'%',
					''
				)}`
			);

			const headerStylingSpecification = (this.headerStyling || '')
				.trim()
				.toLowerCase();
			if (
				headerStylingSpecification.startsWith('mini-') ||
				headerStylingSpecification === 'mini'
			) {
				classList.push('mini');
			}
			return classList.join(' ');
		},
		headerContent: function() {
			const content = this.headerContentText || '';
			return content.trim().length > 0 ? content.trim() : '&nbsp;';
		},
		headerClasses: function() {
			const _this = this;
			const classList: string[] = [];
			switch ((this.headerStyling || '').trim().toLowerCase()) {
				case 'mini-dark':
					classList.push('fixed-top');
					classList.push('border-bottom');
					classList.push('dark-header');
					break;
				default:
					classList.push('fixed-top');
					classList.push('border-bottom');
					classList.push('standard-header');
					break;
			}
			return classList.join(' ');
		},
		showExpirationModal: function() {
			return this.expirationCountdown > 0;
		}
	},
	methods: {
		resetCookies: function() {
			auth.clearAuthCookie();
			auth.clearValidationCookie();
			location.reload();
		},
		logOut: function() {
			this.$gtag.event('logout', {
				event_category: 'process'
			});
			this.reset();
			auth.logOut();
			//Commented out because this was causing a duplicate navigation item error
			//this.$router.push('/');
		},
		reset: function() {
			this.headerContentText = '';
		},
		expirationTick: function() {
			if (--this.expirationCountdown <= 0) {
				if (this.kioskMode && this.kioskBaseUrlRedirect != '') {
					var languageCode = this.$i18n.locale.split('-')[0];
					window.location.replace(
						this.kioskBaseUrlRedirect +
							`/done/attendant-issues?lang=${languageCode}`
					);
				} else {
					// save and logout
					if (
						(this.currentProcess &&
							this.currentProcessStep === 2 &&
							this.currentProcess.name === 'functional') ||
						(this.currentProcess &&
							this.currentProcess.name ===
								'direct:FormsOverview' &&
							this.currentProcessStep ===
								this.currentProcess.steps.length - 1)
					) {
						EventBus.$emit('save-logout');
					} else {
						this.logOut();
					}
				}
			} else {
				this.refreshExpirationTimer();
			}
		},
		refreshExpirationTimer: function() {
			this.clearExpirationTimer();
			this.expirationTimer = setTimeout(this.expirationTick, 1000);
		},
		clearExpirationTimer: function() {
			clearTimeout(this.expirationTimer || undefined);
			this.expirationTimer = null;
		},
		extendExpiration: function() {
			const _this = this;
			_this.clearExpirationTimer();
			aw_api.axios
				.post('v1/SCS/Is/Active')
				.then((response: AxiosResponse) => {
					if (response.status === 202) {
						_this.expirationCountdown = 0;
						auth.refreshAuthExpiration();
					} else {
						_this.refreshExpirationTimer();
					}
				})
				.catch(_ => _this.refreshExpirationTimer());
		}
	},
	beforeRouteLeave(to, from, next): void {
		// Clear any session data here
		sessionStorage.clear();
		next();
	},
	created(): void {
		EventBus.$on('header-style', (newStyle: string | undefined) => {
			this.headerStyling = newStyle || '';
		});
		EventBus.$on('zoom-level', (zoom: string) => {
			this.accessibilityPanelModel.ZoomLevel = zoom;
		});
		EventBus.$on('dyslexic-font', (dyslexic: boolean) => {
			this.accessibilityPanelModel.DyslexicFont = dyslexic;
		});
		EventBus.$on('enhance-value', (enhanceValue: boolean) => {
			this.accessibilityPanelModel.EnhanceValue = enhanceValue;
		});
		EventBus.$on('basic-mode', (basicMode: boolean) => {
			this.accessibilityPanelModel.BasicMode = basicMode;
		});
		EventBus.$on('language-change', (currentLang: string) => {
			const html = document.documentElement;
			this.$gtag.event('language-change', {
				event_category: 'verbiage',
				event_label: this.$i18n.locale
			});
			html.setAttribute('lang', this.$i18n.locale.split('-')[0]);
		});
		EventBus.$on('doc-save-logout', e => {
			this.$gtag.event('logout', {
				event_category: 'process',
				event_label: 'document save'
			});
			auth.logOut(true, e);
		});

		EventBus.$on('step-complete', (name: string) => {
			this.headerContentText = '';
			this.headerStyling = '';
			this.$gtag.event('step-completed', {
				event_category: 'process',
				event_label: `step: ${name}`
			});
		});
		EventBus.$on('step-reset', () => {
			this.headerContentText = '';
			this.headerStyling = '';
		});
		EventBus.$on('toggle-show-accessibility', () => {
			this.showAccessibility = !this.showAccessibility;
			if (this.showAccessibility) {
				setTimeout(() => {
					let zoomFocus = document.getElementById(
						'accessibility-dropdown-zoom'
					);
					if (zoomFocus != null) zoomFocus.focus();
				}, 250);
			}
		});
		EventBus.$on('hide-accessibility', () => {
			this.showAccessibility = false;
		});
		EventBus.$on(
			'updateHeaderContent',
			(content: string) => (this.headerContentText = content)
		);
		EventBus.$on('user', () => {
			this.user = auth.currentUser;
		});
		EventBus.$on('expiration-imminent', () => {
			this.expirationCountdown = 60;
			this.refreshExpirationTimer();
		});
		EventBus.$on(
			'process-changed',
			newProcess => (this.currentProcess = newProcess)
		);
		EventBus.$on(
			'step-changed',
			newStep => (this.currentProcessStep = newStep)
		);
		EventBus.$on('session-mismatch', () => {
			this.showWrongSessionModal = true;
		});
		EventBus.$on('kiosk-mode', () => {
			// console.log('on kiosk mode');
			this.kioskMode = true;
		});
		EventBus.$on('reset-focus', () => {
			(this.$refs.app as HTMLElement).focus();
		});
		EventBus.$on('set-kiosk-base-url-redirect', (baseUrl: string) => {
			this.kioskBaseUrlRedirect = baseUrl;
		});
		EventBus.$on('redirect-to-kiosk', (redirectUrl: string) => {
			// this.$gtag.event('logout', {
			// 	event_category: 'process',
			// });
			auth.clearAuthCookie();
			//auth.logOut();

			// alert(this.kioskBaseUrlRedirect + redirectUrl);
			window.location.replace(this.kioskBaseUrlRedirect + redirectUrl);
		});

		// var t  = this.isKioskModeEnabled;

		// debugger;
		// const myState = this.$store.state.kiosk;

		// console.log(myState);

		this.user = auth.isAuthenticated ? auth.currentUser : new User();
	}
});
