























































































import { BNavbar, BNavForm, BNavbarBrand, BNavItem, BImg } from 'bootstrap-vue';
import { EventBus } from '@/event-bus';
import Vue from 'vue';
import { aw_api, AxiosResponse } from '@/aw_api';
import FormStatusLegend from '@/components/sign/FormStatusLegend.vue';
import BannerLogo from '@/components/BannerLogo.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import BottomMenuButton from '@/components/BottomMenuButton.vue';
import { facilityInfoHandler } from '@/facility-info-handler';

export default Vue.extend({
	props: {
		accessibilityDisabled: {
			type: Boolean,
			default: false
		},
		helpDisabled: {
			type: Boolean,
			default: false
		},
		showAccessibility: {
			type: Boolean,
			default: false
		},
		showLogo: {
			type: Boolean,
			default: false
		},
		showHelp: {
			type: Boolean,
			default: false
		},
		showFormHelpModal: {
			type: Boolean,
			default: false
		}
	},
	components: {
		// 'b-navbar': BNavbar,
		// 'facility-logo': FacilityLogo,
		// BNavItem,
		// FormStatusLegend
		BannerLogo,
		BottomMenuButton,
		LanguageSelector
	},
	data: function() {
		return {
			vaultId: this.$route.params.vaultId
		};
	},
	methods: {
		accessibilityClicked: function() {
			EventBus.$emit('toggle-show-accessibility');
		},
		helpClicked: function() {
			this.$bvModal.show('modal-help');
		},
		languageClicked: function() {
			this.$bvModal.show('modal-language');
			EventBus.$emit('hide-accessibility');
		},
		changeProcessStep: function() {
			EventBus.$emit('go-home');
		}
	},
	computed: {
		isLogin() {
			const name = this.$route.name;
			if (this.$route && name) {
				return name === 'login';
			}
			return false;
		},
		backEnabled() {
			return true;
		},
		nextEnabled() {
			return true;
		}
	},
	created() {}
});
